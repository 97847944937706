<template>
  <div class="chart-table">
    <div class="btn-div">
      <Button type="primary" @click="linkChart" class="btn">生成柱状图</Button>
      <Button type="info" class="btn" @click="rinkChart">生成饼图</Button>
      <Button type="error" class="btn" icon="ios-cloud-download-outline" @click="exportForm">导出表单</Button>
    </div>
    <!-- <LiefengTable
      :talbeColumns="talbeColumns"
      :tableData="tableData"
      :total="total"
      :curPage="currentPage"
      @hadlePageSize="pageChange"
      :loading="loading"
      :fixTable="true"
      :pagesizeOpts="[20, 30, 50, 100]"
      :pageSize="pageSize"
    ></LiefengTable>-->
    <Table :columns="talbeColumns" height="800" border stripe :data="tableData" :loading="loading"></Table>

    <LiefengModal title="生成柱状图" :value="chartStatus" :fullscreen="true" @input="chartStatusFn">
      <template v-slot:contentarea>
        <div v-if="isShow" style="text-align:center;font-size:16px;font-weight:bold;">
          {{'投件统计分析'}}
          <VeHistogram :extend="hisExtend" :data="histogramData"></VeHistogram>
        </div>
      </template>
    </LiefengModal>
    <LiefengModal title="生成饼图" :value="ringStatus" :fullscreen="true" @input="ringStatusFn">
      <template v-slot:contentarea>
        <div style="text-align:center;font-size:16px;font-weight:bold;">
          {{'投件统计分析'}}
          <VePie
            style="margin-top:-20px;"
            :data="histogramData"
            :legend="{show:false}"
            v-if="ringShow"
          ></VePie>
        </div>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import VeHistogram from "v-charts/lib/histogram.common";
import VePie from "v-charts/lib/pie.common";
export default {
  props: {
    businessCode: {
      type: String,
      default: () => {
        return "";
      }
    }
  },

  data() {
    //这里存放数据
    return {
      talbeColumns: [
        {
          title: "序号",
          type: "index",
          align: "center"
        },
        {
          title: "标题",
          key: "voteItemName",
          align: "center"
        },
        {
          title: "件数",
          key: "total",
          align: "center"
        },
        {
          title: "排行",
          type: "index",
          align: "center"
        },
        {
          title: "所占百分比",
          key: "accounted",
          align: "center"
        }
      ],
      tableData: [],
      loading: false,

      //   柱状图
      chartStatus: false,
      histogramData: {
        columns: ["title", "件数"],
        rows: []
      },
      isShow: true,
      hisExtend: {
        // 数据过多时显示滚动条，暂时隐藏
        dataZoom: [
          {
            show: false,
            realtime: true,
            start: 0,
            end: 50
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 50
          }
        ],
        series: {
          barMaxWidth: 50
        }
      },
      ringStatus: false,
      ringData: [],
      ringShow: true
    };
  },
  //方法集合
  methods: {
    //  方法部分
    // 点击生成饼图按钮
    rinkChart() {
      this.ringStatus = true;
      this.ringShow = false;
      this.$nextTick(() => {
        this.ringShow = true;
      });
    },
    ringStatusFn(status) {
      this.ringStatus = status;
    },
    // 点击生成柱状图按钮
    linkChart() {
      this.chartStatus = true;
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    // 关闭柱状图模态框事件
    chartStatusFn(status) {
      this.chartStatus = status;
    },
    // 点击导出表单按钮
    exportForm() {
      this.$Modal.confirm({
        title: "退出提示",
        content: "<p>正在导出当前页数据，是否继续？</p>",
        onOk: () => {
          this.exportList();
        }
      });
    },
    // 接口部分
    getList(data) {
      this.loading = true;
      this.$get("/voteapi/api/pc/componentVote/statVoteRecordRank", {
        businessType: "2",
        businessCode: data.businessCode
      }).then(res => {
        if (res.code == 200) {
          this.loading = false;
          this.tableData = res.dataList;
          if (res.dataList && res.dataList.length != 0) {
            res.dataList.map(item => {
              this.histogramData.rows.push({
                title: item.voteItemName,
                件数: item.total
              });
            });
            if (res.dataList.length > 10) {
              this.hisExtend.dataZoom[0].show = true;
            }
          }

          console.log(this.histogramData);
        } else {
          this.loading = false;
          this.$Message.console.error({
            content: "获取数据失败",
            background: true
          });
        }
      });
    },
    exportList() {
      let tHeader = [
            "标题",
            "件数",
            "排行",
            "所占百分比"
          ];
          let filterVal = [
            "voteItemName",
            "total",
            "index",
            "accounted"
          ];
          let tableData = JSON.parse(JSON.stringify(this.tableData));
          this.$core.exportExcel(tHeader, filterVal, tableData, "投件统计分析");
      // this.$get('/voteapi/api/pc/componentVote/exportVoteRecordRank',{
      //     businessType: "2",
      //     businessCode: this.businessCode,
      //     voteId: "",
      //     voteItemId: ""
      // }).then(res=>{
      //     if (res.code == 200) {
      //         if (res.data) {
      //           if (
      //             res.data.slice(0, 4) == "http" &&
      //             res.data.slice(0, 5) !== "https"
      //           ) {
      //             res.data = res.data.replace("http", "https");
      //           }
      //         }
      //         console.log(res.data);
      //         let link = document.createElement("a");
      //         link.href = res.data;
      //         document.body.appendChild(link);
      //         link.click();
      //         link.download = "导出全部信息";
      //         document.body.removeChild(link);
      //         this.$Message.destroy();
      //       } else {
      //         this.$Message.warning({
      //           content: res.desc,
      //           background: true,
      //         });
      //       }
      // })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  watch: {
    businessCode: {
      handler(val, newVal) {
        if (val) {
          let data = {
            businessCode: val
          };
          this.getList(data);
        }
      }
    }
  },
  //import引入的组件需要注入到对象中才能使用
  components: { LiefengTable, LiefengModal, VeHistogram, VePie }
};
</script>
<style lang='less' scoped>
//编写css
.chart-table {
  .btn-div {
    position: fixed;
    display: flex;
    top: 8px;
    right: 50px;
    .btn {
      margin-right: 10px;
    }
  }
}
</style>